
.small_phone_container{
    width: 100%;
margin-top: 4%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;

}
.add_event_small_phone_container{
        height: 28vh;
            width: 13.748vh;
background-position: center;
background-size: contain;
background-repeat: no-repeat;
z-index: 5;
position: relative;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;

}
.event_headers_span{
    text-align: center;
    width: 95%;
        white-space: nowrap;

}
.add_event_small_phone_case{
    object-fit: cover;
        border-radius: 10%;
        height: 28vh;
        width: 13.748vh;
        z-index: -1;
        position: absolute;
}
.drag_section{

}
.add_event_small_phone_bg{

    object-fit: cover;
    border-radius: 10%;
    top: 1%;
    height: calc(28vh - 2%);
        width: calc(13.748vh - 6%);
        left: 4%;
z-index: -1;
position: absolute;

}

.small_phone_main{
width: 92%;
position: relative;
height: calc(85%);
margin-bottom: 15%;
}
.small_phone_name_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.small_phone_footer{
    height: 14%;

    width: 90%;    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 6%;

}
.small_phone_circle_1{
    
    border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
width: 5vw;
height: 5vw;
flex-direction: column;
max-width: 18px;
    max-height: 18px;
}
.small_phone_circle_2{
    
    border-radius: 50%;
width: 5.5vw;
height: 5.5vw;
max-width: 24px;
max-height: 24px;
display: flex;
    align-items: center;
    justify-content: center;
}
.small_phone_circle_3{
    
    border-radius: 50%;
width: 5vw;
height: 5vw;
display: flex;
    align-items: center;
    justify-content: center;
    max-width: 18px;
        max-height: 18px;
}
.left_traingle{
    position: absolute;
color: var(--blue);

    font-size: 7vw;
 width:18vw;
 left:-19.5vw ;
 display: flex;
 justify-content: end;
 align-items: end;
}

.right_traingle {
position: absolute;
color: var(--blue);
 width: 18vw;
 right: -19.5vw;
    font-size: 7vw;
     display: flex;
 justify-content: start;
 align-items: end;
}
.small_icon{
    font-size: 80%;
}