.personal_page{
    width: 100vw;
    height: 100%;

display: flex;
flex-direction: column;
/* gap: 20px; */
}


.personal_page_header {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 20px;
    left: 0px;
    height:100px;
  
    width: 100%;
    top: 0;
    backdrop-filter: blur(40px);
    background-color: var(--clear);
    z-index: 2;
    text-align: center;
    position: fixed;
}

.personal_header_footer {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.personal_header_footer_btn {
    font-family: 'Montserrat';
    background-color: transparent;
    border: none;
    color: black;
    font-size: clamp(14px, 8vw, 20px);
}
.personal_page_main{
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
   /* margin-top: 20px; */
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 90px;
}
.personal_page_main::-webkit-scrollbar{
    display: none;
}
.sign_in_page{
    position: fixed
    ;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: var(--clear);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;

    direction: rtl;
    flex-direction: column;
justify-content: center;
gap: 40px;

}
.sign_in_page header{
width: calc(100% );
text-align: center;


display: flex;
align-items: center;
}
.sign_in_page header span{
    font-size: var(--medium);
    width: 80%;
text-align: center;
padding-right: 10%;
}
.sign_container{
    width: 100%;

display:flex ;
align-items: center;
justify-content: center;

}
.log_out_button{
    position: absolute;
    top:10px;
    right: 10px;


}
.log_out_button button{
}