.section_4{
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
}
.phonr_card_container{
    position: absolute;
    width: 40vw;
    height: calc(100vh - 80px);
    right: 0;
    top: 80px;
}
.phonr_card_section_4{
        position: absolute;
            width: 40vw;
            height: calc(100vh - 80px);
            left: 0;
         
}