
.eventContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.event_object{
    width: 100%;
height: 30vw;  
max-height:200px;
display: flex;
    align-items: center;
justify-content: space-around;
gap: 5%;
padding-bottom: 20px;
padding-top: 20px;
position: relative;

/* box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.059); */
    /* Add box-shadow for bottom shadow */}

.event_iphone_section{
    height: fit-content;
    width: 15vw;
    height: 30.5vw;
 
    max-height: 205px;
        max-width: 100px;
    display: flex;
            align-items: center;
                justify-content: center;
                margin-left:5% ;
          
}
.event_image_section{
    display: flex;
height: 100%;
    width:100%;
        align-items: center;
        justify-content: center;
        position: relative;
      
     
filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.259));
    /* Add drop shadow effect */
}
.event_phone_container{
    height: 100%;

 width: 100%;
 max-height: 300px;
background-position: center;
background-size: cover;
background-repeat: no-repeat !important;
}
.event_bg_image_phone{
   object-fit: cover;
  border-radius: 15%;
 position: absolute;
 z-index: -1;
 left: 0px;
 top: 0px;
}
.event_span_container{
    width: 100%;
    height: 65%;
    margin-top: 30%;
   position: relative;

}
.event_obj_name_container{
    position: absolute;
width: calc(95%);
height: fit-content;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
z-index: -1;
left: 1px;
}
.event_obj_name_container span{
width: 90%;
display: flex;
align-items: center;
justify-content: center;
}
.event_text_span{
    text-align: center;
    width: 95%;
}
.event_phone_container_footer{
    position: absolute;
    width: 100%;
    height: 15%;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.event_circle_1,.event_circle_2,.event_circle_3{
    /* height: fit-content;
 width: fit-content;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    padding:0.5vw; */
    position: absolute;
    width: 15%;
    display: flex
    ;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
        font-size: clamp(3px, 1.5vw, 10px);
        height:0;
        padding-bottom: 7.5%;
        padding-top: 7.5%;
}
.event_circle_1{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
font-size:clamp(2px,0.5vw,5px);

scale: 0.9;
left: 70%;
}

.event_circle_2{
    scale: 1.1;
 left: 40%;
}
.event_circle_3{
      
        left: 10%;
}
.event_data_sectoin{
    width: 30%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
max-height: 300px;

position: relative;}
.event_data_sectoin_header{font-size: clamp(14px,5vw,40px); 
display: flex;
align-items: center;

gap: 10%;}
.event_data_sectoin_header span{
width: '100%';
white-space: nowrap;
  overflow: hidden;          /* Hides the overflowed content */
  text-overflow: ellipsis;   /* Displays the ellipsis when the text overflows */
  width: 35vw;              /* Sets a fixed width for the element */
  display: inline-block;
}
.event_data_sectoin_main {font-size: clamp(14px, 5vw, 40px); white-space: nowrap;
}
.event_data_sectoin_footer {
    width: 100%;
    height: 150%;
    display: flex;
    align-items: end;
    justify-content: start;
    gap:clamp(5px,10%,20px) ;
    flex: 1;
}
.event_data_sectoin_footer_icon{

background-color: var(--cream);
opacity: 0.8;

border-radius: 50%;
font-size: clamp(10px, 4vw,30px);
display: flex;
align-items: center;
justify-content: center;
/* width: clamp(5px, 100%, 50px);
    height: clamp(5px, 100%, 50px); */
padding: clamp(4px,3%,8px);

}
.barcode_section{
    /* height: 0;
    width: 12%;
    max-width: 100px;
padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: clamp(0px, 9.5%, 100px);
    padding-bottom: clamp(0px, 9.5%, 100px); */
    width: clamp(10px,100%,90px);
    height: clamp(10px, 100%, 90px);
    padding: clamp(5px,4%,20px);
  /* background-image: linear-gradient(to left , var(--beg),var(--beg-ball)); */
  background-color: var(--cream);
    display: flex;
    align-items: center;
    justify-content: center;
position: relative;
   border-radius: 50%;
   margin-right: 5%;
filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.15));}
.barcode_page{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10;
    background-color: var(--clear)
    ;
    backdrop-filter: blur(20px);
    top: 0;
display: flex;
align-items: center;
justify-content: center;
}
.barcode_container{
background-color: var(--cream);    ;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    width: 80vw;
    height: 80vw;
max-width: 80vh;
max-height: 80vh;
border-radius: 50%;
padding: 10px;
}
.share_barcode_container{
    font-family: 'Montserrat';
    font-size:clamp(10px,5vw,30px);
}
.files_page_button{
    background-color: var(--blue);
    color: white;
    border: none;
    border-radius: 30px;
    text-align: center;
}