.App{
    width: 100vw;
    height: fit-content;
    /* font-family:'Montserrat' ; */
   
}
button{cursor: pointer;}
body{
    
 
    
    user-select: none;
    /* Standard syntax */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
font-size: var(--small);
overflow-x: hidden;
    font-family: "verale";}
        .no-scroll {
            overflow: hidden;
        }
:root {
    --blue: #066fa4c6;
    --beg: #f5cfa2;
    --light-blue: #066fa446;
    --dark-blue: #0e2841;
    --beg-ball:#ecb36e;
    --clear:#ffffff6e;
    --cream:#fff6e8;
    @media (max-width: 900px) {
        --large: clamp(32px, 9vw, 80px);
            --golden-ratio: 1.618;
        
            /* medium is large divided by golden ratio */
            --medium: calc(var(--large) / var(--golden-ratio));
        
            /* small is medium divided by golden ratio */
            --small: calc(var(--medium) / var(--golden-ratio));
                        --ssmall: calc(var(--small) /1.2);
            --xsmall: calc(var(--small) / var(--golden-ratio));
    
            }
--large: clamp(32px,4vw, 75px);
    --golden-ratio: 1.618;

    /* medium is large divided by golden ratio */
    --medium: calc(var(--large) / var(--golden-ratio));

    /* small is medium divided by golden ratio */
    --small: calc(var(--medium) / var(--golden-ratio));
    --xsmall:calc(var(--small) / var(--golden-ratio));
                --xxsmall:calc(var(--xsmall) / var(--golden-ratio));
                                         --ssmall: calc(var(--small) /1.2);
}
.double-border {
    position: relative;
    /* Required for absolute positioning of pseudo-elements */
    border-bottom: 3px  black;
    /* Adjust thickness and color as needed */
}
/*
.double-border::before,
.double-border::after {
    content: '';
    position: absolute;
 
    width: 100%;
    
}

.double-border::before {
    border-bottom: 2px solid var(--blue);
    left: 0;
    bottom: -2px;
}

.double-border::after {
    left: -5px;
        border-bottom: 2px solid black;
        bottom: -7px;
}
*/

@font-face {
    font-family: 'AlexBrush';
    src: url('../Fonts/AlexBrush-Regular.ttf');

}

@font-face {
    font-family: 'AnticDidone';
    src: url('../Fonts/AnticDidone-Regular.ttf');
}@font-face {
    font-family: 'Arapey';
    src: url('../Fonts/Arapey-Regular.ttf');
}@font-face {
    font-family: 'DancingScript';
    src: url('../Fonts/DancingScript-VariableFont_wght.ttf');
}@font-face {
    font-family: 'LibreBodoni';
    src: url('../Fonts/LibreBodoni-VariableFont_wght.ttf');

}@font-face {
    font-family: 'Montserrat';
    src: url('../Fonts/Montserrat-VariableFont_wght.ttf');
}@font-face {
    font-family: 'NanumMyeongjo';
    src: url('../Fonts/NanumMyeongjo-Regular.ttf');
}@font-face {
    font-family: 'PinyonScript';
    src: url('../Fonts/PinyonScript-Regular.ttf');
}
@font-face {
    font-family: 'Mallikhaswash';
    src: url('../Fonts/Mallikhabrush-7B4ME.ttf');
}
@font-face {
    font-family: 'Breathing';
    src: url('../Fonts/Breathing.ttf');
}
@font-face {
    font-family: 'Tangerine';
    src: url('../Fonts/Tangerine-Regular.ttf')
}@font-face {
    font-family: 'Solitreo';
    src: url('../Fonts/Solitreo-Regular.ttf');
}
@font-face {
    font-family: 'Miriam';
    src: url('../Fonts/MiriamLibre-Regular.ttf');
}
@font-face {
    font-family: 'Tinos';
    src: url('../Fonts/Tinos-Italic.ttf');
}
@font-face {
    font-family: 'AmaticSC';
    src: url('../Fonts/AmaticSC-Regular.ttf');
}
@font-face {
    font-family: 'ארימו';
    src: url('../Fonts/Arimo-Italic-VariableFont_wght.ttf');
}
@font-face {
    font-family: 'openSans';
    src: url('../Fonts/OpenSans-VariableFont_wdth\,wght.ttf');
}




@font-face {
    font-family: 'verale';
    src: url('../Fonts/VarelaRound-Regular.ttf');
}
@font-face {
    font-family: 'fredoka';
    src: url('../Fonts/Fredoka-VariableFont_wdth\,wght.ttf');
}
@font-face {
    font-family: 'alef';
    src: url('../Fonts/Alef-Regular.ttf');
}
@font-face {
    font-family: 'noto';
    src: url('../Fonts/NotoSansHebrew-VariableFont_wdth\,wght.ttf');
}
@font-face {
    font-family: 'belle';
    src: url('../Fonts/Bellefair-Regular.ttf');
}