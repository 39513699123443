
 
 .event_text_config {
     position: absolute;
     z-index: 10;
     width: fit-content;
     min-width: 300px;
     height: 40vh;
     box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.134);
     border-radius: 20px;
     left: -0;
     animation: appear 0.5s forwards;
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     backdrop-filter: blur(10px);
background-color: rgba(255, 255, 255, 0.833);
gap: 20px;
overflow-y: auto;
padding-top: 10px;
padding-bottom: 10px;
direction: ltr;
 }

 .dropdown{
width: 100%;
height: 100%;
border-radius: 5px;
height: fit-content;
gap: 10px;
direction: rtl;
font-size: var(--small);
padding-left: 2px;
padding-right:2px;
 }


 .font_option{
    text-align: center;
            box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.145);
            background-color: rgb(255, 255, 255);
            backdrop-filter: blur(40px);
            padding-top: 5px;
            padding-bottom: 5px;
       
 }
 .AlexBrush{
    font-family: 'AlexBrush';

 }
 .AntiDidone{font-family: 'AnticDidone';
    
}
.Arapey{font-family: 'Arapey';
    
}
.DancingScript{font-family: 'DancingScript';
}
.LibreBodoni{font-family: 'LibreBodoni';
}
.Montserrat{
    font-family: 'Montserrat';

}
.NanumMyeongjo{
    font-family: 'NanumMyeongjo';

}
.PinyonScript{font-family: 'PinyonScript';
}
.Tangerine{font-family: 'Tangerine';

}
.Solitreo{font-family: 'Solitreo';}
.Mallikhaswash{
    font-family: 'Mallikhaswash';
}
.Breathing {
    font-family: 'Breathing';
}
.Miriam {
    font-family: 'Miriam';
    
}
.Tinos {
    font-family: 'Tinos';
    
 

}
.AmaticSC {
    font-family: 'AmaticSC';
}
 @keyframes appear {
     0% {
         opacity: 0;

     }


     100% {
         opacity: 1;
     }


 }
 .font_selecting_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
 }
 .button_color_ref{
    position: relative;
 }
 .edit_test_span{
   font-size: 22px;
 }
.font_sizes_container {
 display: flex;
 flex-direction: row;
 width: 100%;
 align-items: center;
 justify-content: center;
 gap: 15%;
}
.color_input{
        outline: none;
            border-radius: 50% ;
            width: 50px !important;
            height: 50px;
            border: 1px solid black;
            background-color: transparent;

}
.set_event_name_container{
    width: calc(100% );
    height: fit-content;
    display: flex;
    align-items: center;
    gap:10px;
    border-radius: 10px;
                

}
.edit_text_section {
    width: 65%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        border-radius: 10px;
      padding-left: 5px;
      padding-right: 5px;
            padding-top: 5px;
            position: relative;
}
.set_event_name_container_text_input{
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: center;
    font-size: var(--small);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.563);
    height: 90%;
  
    font-size: 20px;
}
.set_event_name_container_text_input:focus{
    outline: none;
}
.text_config_section{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
   ;position: relative;
}
.edit_text_buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    flex-direction: row-reverse;
}
.font_color_button{
    position: relative;
    width: var(--small);
    
height:var(--small);
border: 1px solid black;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.font_color_button input{
    width: 100%;
    height: 100%;
}
.text_bold_button{
    border: none;
    background-color: transparent;
    border-radius: 5px;
    color: rgba(99, 99, 99, 0.76);
    font-size: var(--small);
    height: 100%;
width: fit-content;
}
.text_bold_button span{
padding-left: 5px;
padding-right: 5px;
}
.bold_text{
    font-weight: 700;
    color: black;
}
.fonts_swiper_container{

width: 50%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px;
padding-left: 10px;
padding-right: 10px;


}
.fonts_scroller::-webkit-scrollbar{
    display: none;
}
.fonts_scroller{
display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow-x: auto;
}
.font_swiper_slide{
text-align: center;
}
.font_swiper_slide span{

    height: 100%;
}
.text_color_section{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.opacity_slider_container{
    width: 70%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.text_color_section span{
    font-size: var(--xsmall);
}
.mySwiper{
   padding-left: 10px;
   padding-right: 10px; 

}
.panel{
    font-size: var(--xsmall);
    align-items: center;
    text-align: center;
    border-radius: 5px;
}
.panel::-webkit-scrollbar{
    display: none;
}