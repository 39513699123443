.timer_component {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(15px);
    background-color: #ffffffd4;
    direction:rtl
        ;text-align:center
}
.discount_boxx {
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center
}
.countdown-timer{
    display:flex;
    direction:ltr
}
.countdown-section {
    display: flex;
    flex-direction: column;
    align-items: start
}
.countdown-value{
    font-size:var(--large)
}
.disoff {
    font-size: var(--large);
    color: var(--blue);
}
.close_disc_page{
    position:absolute;
    top:25px;
    left:25px;
    font-size:40px
}