.section_3{
    width: 100vw;
    height: 100vh;
 position: sticky;
 top: 0;
}
.section_3_header{

}
.section_3_main{

        
}
.section_3_main p{
  
}
