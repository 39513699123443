


.choising_data_section {
 
    display: flex;
    flex-direction: column;

    justify-content: start;
   gap: 10px;

    width: calc(96%);
        max-width: 900px;
z-index: 10;
  height: fit-content;
  margin-right: 0%;

}
.edit_event_section{
    display: flex;
    flex-direction: row-reverse;
        justify-content: start;
            gap: 10px;
}

.button_color_input_container {
 backdrop-filter: blur(50px);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.107);
    background-color: var(--clear);
    width: fit-content;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: var(--xsmall);
    padding-left: clamp(5px,3%,10px);
    padding-right:clamp(5px, 3%, 10px);
}
.button_color_ref{
    width: 45px;
    height: 45px;
    margin: 5px;
    border-radius: 50%;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.053);
        z-index: 1;
}
.button_color_input_container span {
    word-wrap: break-word;
    width: 90%;
    text-align: center;
}

.button_color_input_container input {
    outline: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 1px solid black;
    background-color: transparent;

}

.name_date_input_container {
        width: calc(35vw - 40px);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
 gap: 10px;

    z-index: 12;
}
.A_button{

}
.event_name_input {
    height: 45%;
    width: 100%;
backdrop-filter: blur(10px);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.107);
        background-color: var(--clear);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.event_name_input button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: black;
    position: relative;
    
}

.event_name_input input {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    width: 80%;

    text-align: center;
}
.set_event_date_containerr{
    display: flex;
    gap: 10px;
}


.config_container {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: 100%;

    border-radius: 12px;
    backdrop-filter: blur(50px);
    background-color: var(--clear);
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.097);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding-bottom: 5px;

}

.config_header {
    font-size: 14px;
    text-align: center;
direction: rtl;
    padding-top: 10px;
}

.config_container_main {
    height: 100%;
    width: 100%;
    flex: 1;
    border-radius: 12px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.config_container_main::-webkit-scrollbar {
    display: none;
}

.config_option {
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 12px;
    padding: 5px;
    text-align: center;
    transition: 0.5s all;
font-size: var(--xsmall)
;




}

.selected_config_option {
    transition: all 0.5s;
}

.config_date_container {
    font-size: var(--xsmall);

    position: relative;
}

.config_date_container input {
    background-color: transparent;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 5px;
    border-radius: 12px;
    font-family: 'Montserrat';
    text-align: center;
}

.config_date_container input:focus {
    outline: none;
}

.config_date_main {
    height: 100%;
    width: 100%;
    flex: 1;
    border-radius: 12px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;
}

.section_2_left_config {
    height: 80%;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}
.button_config_section{
    display: flex;
    align-items: center;
    gap: 10px;
}
.set_bg_img_input{
height: 100%;
border-radius: 10px;
width: fit-content;
font-size: var(--xsmall)
;
display: flex;
align-items: center;
flex-direction: column;
justify-content: space-around;
padding-left: 10px;
padding-right: 10px;
}
 @media (max-width: 900px) {
  
     .name_date_input_container {
         width: calc(100% - 40px);
     }
 }
 .bg_box{
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.145);
        background-color:var(--clear);
        backdrop-filter: blur(40px);

 }
 .button_config{
    width:fit-content;
  
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

gap: 20px;
        padding: 10px;
    padding-left:20px;
    padding-right: 20px;
    border-radius: 10px;
 }
 .lan_button{
    border: none;
background-color: transparent;
padding: 5px;
border-radius: 5px;
color: rgba(85, 85, 85, 0.759);
font-size: var(--xsmall);
 }
 .lan_selected{
color: black;
font-weight: 600;
 }