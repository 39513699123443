.logo_page{
 
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 22;
    animation: logo_page_fade forwards 2.5s 2s;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
@keyframes logo_page_fade {
    0% {

        opacity: 1;
    }


    100% {

        opacity: 0;
        display: none;

    }


}
@keyframes logo_animation {
    0% {
opacity: 0;
        /* transform: scale(2);
        box-shadow: rgb(38, 57, 77) 0px 10px 100px -20px; */
    }


    100% {
opacity: 1;
                /* transform: scale(0.7);
                            box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
          

    }


}
.logo_page_logo_container{
    width: fit-content;
    height: 30vw;

    max-height: 300px;
    /* background-image: linear-gradient(to right,var(--beg-ball),var(--blue)); */
        /* background-image: radial-gradient(circle at bottom left,rgb(255, 237, 215) , var(--beg) 4%, var(--light-blue) ,var(--blue)); */
    border-radius: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
animation:

        logo_page_fade 2s forwards 1s;
}
.logo_page_logo_container span{
    font-size: clamp(30px,15vw,100px);
        font-weight: 200;
        color:var(--dark-blue);

}