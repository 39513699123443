
.config_event_containr {
    width: calc(90% );
    margin-right: 4%;
    max-width: 900px;
    display: flex;
    gap: 20px;
    align-items: start;
    z-index: 1;
    height: fit-content;
    position: relative;

    font-size: var(--xsmall);
  
    direction: rtl;
    
}
.sliders_section{
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:10px;
   
    
}
.total_cost{
    width: fit-content;
    align-items: center;
  padding: 10px;
  height: calc(100% - 20px);
}
.slider_container{
    width: calc(100% - 10px);
    display: flex;flex-direction: row-reverse;
    align-items: center;
    gap: 10px;

   align-items: center;
   justify-content: center;
        padding-bottom: 10px;
        padding-top: 10px;
    
}
.slider_footer{
    position: absolute;
    width: 70%;
    bottom: 10px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
        text-decoration: none;
            cursor: default;

}
.guests_number{
        border-radius: 5px;
            backdrop-filter: blur(50px);
            background-color: var(--clear);
            box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.097);
            padding: 5px;
            min-width: 35px;
            text-align: center;
}
.slider_marks {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size:var(--xsmall);
    margin-left: 200%;
}
.photos_slider_marks{
    display: flex;
   flex-direction: column;
   align-items: center;
    text-align: center;
    font-size: var(--xsmall);
}
.slider_mark_span{
    display: flex;
    align-items: center;
    gap: 2px;
}
.free_mark{
  
        margin-left: 350%;
    
}
.geust_number_input{
text-align: center;
        border: none;
        font-size: 20px;
        width: 80%;
        margin-right: 5%;
        background-color: transparent;
}
.geust_number_input:focus{
    outline: none;
}
.right_section{
    width: fit-content;
    display: flex;
    flex-direction: column;
justify-content: center;

    gap:10px;
   
}
.how_many_container{
                border-radius: 5px;
                  
                    padding: 5px;
                    min-width: 35px;
                    text-align: center;
         
                    width: fit-content;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    min-width: 100px;
              
}
.total_container{
        border-radius: 5px;
        
            padding: 5px;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;

}
.marker_span{
    border-bottom: 0px;
    outline: none;
        text-decoration: none;
        cursor: default;
}