.small_phone_3d_cntainer{



}
.small_continue_button{
    position: absolute;
    bottom: 20px;
    width: 100vw;
left: 0;
display: flex;
align-items: center;
justify-content: center;

}
.small_event_section{
  
    position: absolute;
    bottom: 30px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: start;

}