.filter_page {
    position: fixed;
    top: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.521);
    backdrop-filter: blur(40px);
z-index: 3;
}

.filter_main {
    width: 100%;
    flex: 1;

    max-height: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
position: relative;


}
.mirror_img{
    transform: scaleX(-1);
}

.capture_img_container_rotate {

width: calc(100vw - 200px);
    background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
   height:100vw;
 transform:rotate(90deg);
}

.rotate_img {
    transform: scaleX(-1);
}

.webcam_container {
    background-color: antiquewhite;
    max-height: 60vh;
    position: relative;
}

.filter_footer {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 4;
}


.filter_images_container {
    width: 100%;
    height: 120px;
    overflow-x: auto;
    display: flex;
    align-items: center;
    gap: 2px;
}
.flip_img {
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    padding: 5px;
    font-size: 35px;
    position: absolute;
   top: 20px;
   left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;


}
.flip_img_rotate{
    transform: rotate(90deg);
}
.filter_images_container::-webkit-scrollbar {
    display: none;
}

.filter_img {
    height: 90%;
    width: auto;
}

.selected_filter_img {
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    z-index: 4;

}

.filter_buttons_container {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 4;
}

.filter_btn {
 border: none;
 background-color: transparent;
 color: white;
 scale: 1.2;
 font-family: 'Montserrat';
 font-size: 16px;
}

.flip_btn {
    font-size: 40px;
    color: white;
}

.cancle_filter_button {
    font-family: 'Montserrat';
    background-color: transparent;
    border: none;
    color: white;
    scale: 1.2;
}

.capture_img_page {
    position: fixed;
    z-index: 11;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.capture_img_page_main {
    width: 100%;
    height: calc(100% - 220px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;


}

.capture_img_container {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}

.capture_img_page_footer {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.capture_img_page_footer_button {
    font-family: 'Montserrat';
    background-color: transparent;
    border: none;
    color: white;
    scale: 1.2;
}

.rotate_page {

    transform: rotate(-90deg);
    transform-origin: left top;
    /* Set content width to viewport height */
    width: 100vh !important;
    /* Set content height to viewport width */
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
}
