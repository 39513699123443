
.event_section_3_left{
    width: calc(100%);
        height: calc(100% );
    
    
        position: absolute;
        display: flex;
    
    
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
        gap: 15px;
        direction: rtl;
}
.log_sign_container{
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    width:90%;
    max-width: 800px;
}
.sign_in_cntainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width:fit-content;
    justify-content:center ;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.107);  
   border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.335);
padding: 4% 4% 0 4% ;
}
.sign_in_cntainer main{
    display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex: 1;
}
.google_button{
    font-family: "Montserrat";
    border: none;
    background-color:transparent;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
                 .button_img {
                     height: var(--medium);
        
        
                 }
.or_container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    gap: 10px;

}
.line_container{
    height: 1px;
    width: 45%;
    flex: 1
    ;
    background-color: black;
}
.sign_in_input_section{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    gap: 10px;

}
.sign_in_input_container{
    display: flex;
    justify-content: space-around;
    gap: 10px;
width: 100%;
}
.email_input_container input{
border: none;
background-color: transparent;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
padding: 5px 0px 5px 2px;
border-radius: 5px;
width: 100%;
direction: ltr;
text-align: center;
font-size: var(--ssmall);

}
.email_input_container input:focus{
    outline: none;

}
.already_account_button{
    border: none;
    background-color: transparent;
    ;
    font-size: 12px;
    text-align: start;
    padding-top: 10px;
}
.confi_container{
    padding-right: 25px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    font-size: var(--ssmall);
}
.check_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    padding-top: 5px;
}
@keyframes zoom_out {
    0% {

        opacity: 1;
        scale: 1;

    }



   30% {

        opacity: 0;
        scale: 1.6;

    }
70%{

        opacity: 0;
        scale: 1.6;

    }
             
    100%{
            opacity: 1;
                scale: 1;
    }


}
.zoom_out{
    animation: zoom_out 1.2s 0.2s forwards;}

        @keyframes zoom_in {
    0% {
    
            opacity: 1;
            scale: 1;
    
        }
    
    
    
        30% {
    
            opacity: 0;
            scale: 1.6;
    
        }
    
        70% {
    
            opacity: 0;
            scale: 1.6;
    
        }
    
        100% {
            opacity: 1;
            scale: 1;
        }
    
        }
    
        .zoom_in {
            animation: zoom_in 1.2s 0.2s forwards;
        }
      
       .sign_in_error_message{
font-size:var(--xsmall);
margin-top: 4px;
color: red;
       }
       .change_user{
        background-color: transparent;
        font-size: var(--xsmall);
        border: none;
        display: flex;
        align-items: start;
       }
       .sign_in_footer{
        height: 50px;
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
        padding-bottom: 8px;
        gap:10px

       }
.forget_password {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    font-size: var(--small);
}
.forget_password_container{
    width:50vw;
    background-color:aqua;

}
    .forget_password_container span{
        width:80%
    }
    .sign_in_continue_buttton {
        width: fit-content;
        background-color: var(--dark-blue);
        border: none;
        border-radius: 30px;
        padding-left: 20px;
        padding-right: 20px;
        color: rgb(255, 255, 255);
        cursor: pointer;
        font-size: var(--small);
    }
                .terms_page{
        direction: rtl;
       
        background-color: white;
        position: fixed;
        z-index: 9999;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        overflow: auto;
        display: flex;
        align-items: center;
justify-content: center;

                }
                .term_text{
                    width: 90%;
                height: fit-content;
position: absolute;
top: 0;
padding-bottom: 20px;
                }