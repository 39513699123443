.filesPage{
display: flex;
flex-direction: column;
align-items: center;
padding-bottom: 100px;}
.filesPage header{
    width: calc(100% - 40px);
   
    text-align: end;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.filesPage header span{
    font-size: var(--medium);
}
.phoneImage{
  height: calc(1.5 * 28vh);
        width: calc(1.5 * 13.748vh);
}
.object_stack{
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
gap: 20px;
}
.phoneImageComponent{

}
.phoneImageComponent img{
    height: auto;
    object-fit: contain;
}
.posterCovers_section{
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: start;
  width: 95%;
  padding-left: 15px;
  margin-top: 20%;
  
}
.posters_section{
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 0px;
  align-items: center;
  justify-content: start;
  position: relative;
width: 70vw;
  scroll-snap-type: x mandatory;
padding-inline: 15vw;
}
.posters_section > * {
  scroll-snap-align: start; /* Ensures each poster aligns to the start */
}
.posters_section::-webkit-scrollbar{
  display: none;
}
.posterCover_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
  margin-bottom: 20px; /* Spacing between posterCovers */
  position: relative;
}
.posterCover_header_1{
  position: absolute;
  top: 12%;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
    font-size: 200%;


}
.posterCover_header_2{
  position: absolute;
  top: 20%;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  font-size: 150%;

}
.posterCover_image {
  width: 70vw;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.phone_image {
  width: 32%;
  height:auto;
  object-fit: contain;
  position: absolute;
  right: 11.5%;
  top: 33.5%;

}
.qr_image_container{
  position: absolute;
  left: 15%;
  display: flex;
  bottom: 33%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width:26%;
  gap: 2px;
}
.qr_image_container span{
  font-size: 60%;
}
.qr_image{
    width: 100%;
  height: 100%;
  object-fit: contain;


}