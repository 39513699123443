




.home_page{
    width: 100vw;
    height: 1100vh;
    position: relative;
display: flex;
flex-direction: column;
overflow: hidden;
}

body{
    overflow-x: hidden;

  
}
button{color: black;}
body::-webkit-scrollbar{
    display: none;
}
.home_page_section_container{
    height: 300vh;
    position: relative;
    display: flex;
    justify-content: end;
margin-top: 50px;

}
.home_page_section_4_container{
    height: calc(100vh );            position: relative;
            display: flex;
            justify-content: end;
            align-items: start;
}
.home_page_section_div{
    width: 56vw;
    margin-right: 4vw;
    height: calc(100vh - 100px);
    margin-top: -50px;
    display: flex;
    align-items: center;
    z-index: 3;
    justify-content: end;
}
/* .section_2_container {
    height: 250vh;
    position: relative;
    margin-top: 145vh;

}
.section_3_container{
        height: 200vh;
            position: relative;
            margin-top: 70vh;
            z-index: -1;
}
.section_4_container{
    height: 200vh;
        position: relative;
        margin-top: 50vh;
   
} */
.home_page_bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
        filter: blur(30px);
        background-color: rgb(255, 250, 243);
/* background-color: antiquewhite; */

}
/* .glass{
    position: fixed;
    width: calc(100vw - 80px);
    height:calc(100vh -  80px);
    backdrop-filter: blur(30px);
    box-shadow: 0px 00px 10px 4px rgba(0, 0, 0, 0.142);
    top: 40px;
    left: 40px;
    z-index: -1;
    border-radius: 20px;

} */

 .section_main_span{
    font-size: var(--small) !important;
  direction: ltr;
    text-align:justify start !important;
    margin-top: 5%;

 }
.card_3 {
    width: 50vw;
    height: 100vh;
    position: relative;
    position: fixed;
    top: 0;
    z-index: 0;
    display: flex;
    align-items: center;
}
.phone_card_container {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
}
.bg_shape_1 {
    position: absolute;
    width: 30%;
    height: 20%;
    background-color: var(--blue);
    top: 10%;
    right: -10%;
    border-radius: 50%;
    box-shadow: 0px 0px 100px 60px var(--blue);
    opacity: 0.6;
}

.bg_shape_2 {
    position: absolute;
    width: 10%;
    height: 10%;
    background-color: var(--beg-ball);
    top: 30%;
    left: 0%;
    border-radius: 50%;
    box-shadow: 0px 0px 100px 60px var(--beg-ball);
    opacity: 0.6;

}

.bg_shape_3 {
    position: absolute;
    width: 30%;
    height: 30%;
    background-color: var(--beg-ball);
    top: 60%;
    right: 5%;
    border-radius: 50%;
    box-shadow: 0px 0px 100px 60px var(--beg-ball);
    opacity: 0.6;
}
.small_screen_home_page_section_div{
    display: none;
}
.bg_shape_4 {
    position: absolute;
    width: 30%;
    height: 30%;
    background-color: var(--blue);
    top: 90%;
    left: 10%;
    border-radius: 50%;
    box-shadow: 0px 0px 100px 60px var(--blue);
    opacity: 0.6;
}
.bg_shape_5 {
    position: absolute;
    width: 10%;
    height: 10%;
    background-color: var(--blue);
    top: 50%;
    left: 40%;
    border-radius: 50%;
    box-shadow: 0px 0px 100px 60px var(--blue);
    opacity: 0.6;
}
.section_1_footer {
    width: calc(100vw);
    height: 50px;
    position: fixed;
    bottom: 20px;
  left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;}
.section_1_create_event {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.section_1_create_event_button {
    border: none;
    background-color: var(--dark-blue);
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    color: white;
    font-size: var(--small);
    z-index: 10;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}

.section_1_create_event_button span {
    font-size: var(--small);
    color: white;
height: 100%;
padding-bottom: 2px;

}
   @media (max-width: 900px) {
       .home_page {
           height: 700vh;
           width: 100vw;
       }
       .home_page_section_container{
        height: 200vh;
        flex-direction: column;
        justify-content: start;
        align-items: end;
       }
.home_page_section_div{
    width: 92vw;
    height: calc(40vh);
align-items: center;
}
.card_3{
    width: 100vw;
    height: 50vh;
    
top: calc(250vh - 75px);
position: absolute;
}
.small_screen_home_page_section_div{
display: block;
width: 100%;
height:40%;
display: flex;
margin-top: 50px;
position: absolute;
}
.section_4_small{
    margin-top: -100px;
}
.section_1_event_phone_img{
    position: absolute;
    top: 0;
 
right: -10%;;
    width: 90%;
    object-fit: contain;
}
.section_1_gallery_phone_img{
    position: absolute;
        top: 0;
    left: -10%;
        object-fit: contain;
        width: 90%;
/* filter: drop-shadow(50px 50px 60px rgba(0, 0, 0, 0.35)); */
display: flex;
align-items: center;
justify-content: center;
}
.section_1_event_phone_image{
    width: 100%;
    height: 100%;
    
}
.motion_img_container{
    width: 100%;
position: relative;
position: absolute;
height: 50%;
}
.section_1_footer{
  justify-content: center;
bottom:10px;
height: 50px;
position: fixed;
}
.section_1_create_event_button{
    padding-left: 40px;
    padding-right: 40px;
    scale: 1.2;
}
   }