/* .section_1{
    width: 100%;
    height: 100vh;
position: relative;
position: sticky;
top: 0;
left: 35vw;

display: flex;

direction: rtl;
} */
.section_1{
    direction: rtl;
    width: 100%;
    height: 100%;
    position: relative;
}
.section_1_home_page{
    width: 100%;
    height: calc(100% - 50px);

margin-top: 50px;
    flex-direction: column;
        justify-content: center;
        padding-right: 30px;
        display: flex;
    gap: 0px;
}
.section_1_header {

    width: 100%;
    height: fit-content;
display: flex;
flex-direction: column;

}
.section_1_header_span {
    font-size: var(--large);
   
}
.section_1_main{
    width: 80%;
    height: fit-content;
    margin-bottom: 20px;
    display: 1;
    font-size:var(--small);
    display: flex;
    flex-direction: column;
    justify-content: center;


}
.section_1_main p{
    width: 90%;
    text-align: justify;
}
.more_event{
    margin-right: 7vw;
}
.section_1_main span{
        font-size: var(--medium);
        width:90%;
        text-align: end;
}


.section_1::-webkit-scrollbar{
    display: none;
   
}
.card_page{
position: absolute;
width: 100%;
height: 100%;
z-index: 7;

}

.header_span_1{
    font-size: clamp(20px, 8vh, 50px);
    font-weight: 200;
}
.image_card{
    width: 12vw;
    height: 12vw;
    position: absolute;
z-index: -1;
}
.image_card_container{
    width: 12vw;
            height: 12vw;
            position: relative;
            padding: 8px;
            background-color: rgb(241, 241, 241);
}
.image_card_container img{
        width: 12vw;
            height: 12vw;
object-fit: contain;

}
