.order_summery_page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.event_section_4_main{
    
    height: fit-content;
width: calc(96% );
    max-width: 900px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    font-size: var(--xsmall);
    height:fit-content;

}
.order_summary_section{
    display: flex;
    gap: 10px;
      
        border-radius: 20px;
  

    
}
.order_summery_div{
display: flex;
flex-direction: column;
padding: 10px;
border-radius: 10px;
width: 100%;
flex: 1;
gap: 5px;
    padding: 10px;
}
.order_summery_div div{
    display: flex;
text-align: start;
align-items: start;
gap: 5px;
}
.total_price_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: var(--small);
    border-radius: 10px;
    padding-left: 5%;
    padding-right: 5%;
  

}
.total_price_cntainer span{
    white-space: nowrap;
}
.coupon_section{
    display: flex;
    width: 100%;
    gap: 10px;

}
.coupon_container{
    width: 100%;
    flex: 1;
    border-radius: 10px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
padding: 10px;
gap: 5px;
height: fit-content;
}
.coupon_container footer{
    width:calc(100%);
    display: flex;
    align-items: center;
    justify-content: end;


}
.coupon_input{
    border: none;
    background-color: transparent;
    border-radius: 8px;
    font-size: var(--small);
text-align: center;

}
.coupon_input:focus{
    outline: none;
}
.payment_section{
    display: flex;
    gap: 10px;
    width: 100%;

position: relative;
height: fit-content;
}
.paypal_container{
          
                border-radius: 10px;
                display: flex;
                      background-color: var(--clear);
                      position: relative;
          
width: 100%;
flex: 1;


                      justify-content: space-around;
                      align-items: start;
                    
         
        height: fit-content;
        
}
.paypal_container span{
    padding: 10px 10px 0 10px;
}
.paypal_div{
width: 100%;
height:fit-content;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
          padding-top: 0px; 
}

.total_price_cntainer{
    display: flex ;
    flex-direction: column;
   text-align: start;
    gap: 10px ;
}
.total_price_span{
text-align: start;
}
.paypal_button_container{
    width:100px;

}
 @media (min-width: 901px) {
  

     .paypal_div {
         padding-top: 20px;
     }
 }