.upload_container{
    z-index: 10;
    position: fixed;
  
    background-color: var(--clear);
    backdrop-filter: blur(5px);

   top: 2%;
   left: 2%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
 
        display: flex;
        align-items: center
        ;
        flex-direction: column;
        justify-content: center;
        direction: rtl;
        align-items: center;
        justify-content: center;
        text-align: center;
      padding: 10px;
      gap: 5px;
  width: fit-content;
        
}
.upload_header{
            display: flex;
                align-items: center;
                justify-content: space-around;
                direction: rtl;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: fit-content;
                gap: 10px;
}
.upload_container span{
white-space: nowrap;
    display: inline-block;
}
.waiting_beat{
    display: flex;
    align-items: center;
    justify-content: center;
width: 100%;
height: 100%;}