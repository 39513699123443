.phone_3d_cntainer{
    width: 40%;
    height: 100%;
    position: absolute;
    /* right: 0; */
    top: 0;
z-index: 10;
display: flex;
align-items: center;
justify-content: center;
        padding-top: 2%;
}
.go_back_buttn{
    position: fixed;
    top: 3%;
    left: 3%;
    font-size: 40px;
    z-index: 20;
}
.event_section_page{
        width: 100%;
        height: 100%;
        flex: 1;
}
.event_section{
 
    width: 60%;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    align-items: center;
    position: relative;
    
}
.add_event_page{
    width: 100vw;
    overflow: hidden;
position: fixed;
height: 100%;
top: 0;
display: flex;
flex-direction: column;

}
.header_span_2 {

    /* max-width: 50%; */
}
@keyframes slide_left {
    0% {
        right: 0vw;
        scale: 1;

    }
        20% {
            right: -20vw;
                scale: 1;
    
        }
                   60% {
                      scale: 1.4;
        
                   }

    100% {
        right:60vw;
        scale: 1;
    }


}
.silde_left{
    animation: slide_left 1.5s 0.4s forwards ;
    right: 0;
        
}

@keyframes slide_right {
    0% {
        right: 60vw;
     

    }

    20% {
        right: 80vw;
        scale: 1;

    }
                          60% {
                              scale: 1.4;
            
                          }

    100% {
        scale: 1;
        right:0vw;
    }


}

.slide_right {
    animation: slide_right 1.5s 0.4s forwards;
    right: 60vw;

}
@keyframes slide_up {
    0% {
        top: 60%;
        scale: 1;

    }

    20% {
        top: 70%;
        scale: 1;

    }

    60% {
        scale: 1.4;

    }

    100% {
        top: 10%;
        scale: 1;
    }

}
@keyframes slide_up_1 {
    0% {
        top: 40%;
        scale: 1;

    }

    20% {
        top: 70%;
        scale: 1;

    }

    60% {
        scale: 1.4;

    }

    100% {
        top: 10%;
        scale: 1;
    }

}
@keyframes slide_down {
    0% {
        top: 10%;
        scale: 1;

    }

    20% {
        top: -15%;
        scale: 1;

    }

    60% {
        scale: 1.4;

    }

    100% {
        top: 60%;
        scale: 1;
    }

}
@keyframes slide_down_1 {
    0% {
        top: 10%;
        scale: 1;

    }

    20% {
        top: -15%;
        scale: 1;

    }

    60% {
        scale: 1.4;

    }

    100% {
        top: 40%;
        scale: 1;
    }

}
@keyframes screen_slide_right {
    0% {
        right: -40vw;

    }

    
   20% {
       right: -60vw;

   }
    100% {
        right: 0vw;
    }


}

.screen_slide_right {
    animation: screen_slide_right 1.5s 0.4s forwards;
    right:-40vw;

}
@keyframes screen_slide_left {
    0% {
        right: 0vw;

    }

   20% {
       right: 20vw;

   }

    100% {
        right: -40vw;
    }


}
.screen_slide_left {
    animation: screen_slide_left 1.5s 0.4s forwards;
    right:0vw;

}
@keyframes screen_slide_up {
    0% {
        top:35%;

    }

    20% {
        top: 60%;

    }

    100% {
        top: 0%;
    }


}
@keyframes screen_slide_down {
    0% {
        top: 0%;

    }

    20% {
        top: 0%;

    }

    100% {
        top: 35%;
    }


}

.continue_button{
    width: 100vw;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
padding-bottom: 10px;
    z-index: 10;
    gap: 10px;
flex-direction: column;
}
.go_back_button{
    background-color: rgba(255, 255, 255, 0.674);
    backdrop-filter: blur(20px);
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;

    border: none;
color: black;

}
.event_section_1_left{

    width: 100%;
    height: calc(100%);
direction: rtl;

    position: absolute;
    display: flex;

flex-direction: column;
align-items: center;
justify-content: center;


}
.event_section_1_right {

    width: calc(100% );
    height: calc(100%);
justify-content: center;
direction: rtl;
    position: absolute;
    display: flex;

    flex-direction: column;
    align-items: center;

}
.event_section_1_header{
    display: flex;
    align-items: start;
    flex-direction: column;
gap: 0px;
direction: rtl;
padding-right: 5%;
padding-top: 15px;
}
.header_span_1{
font-size: var(--medium);
}
@keyframes fade_out {
    0% {
 
opacity: 1;

    }


  
    100% {

       opacity: 0;

    }


}

.fade_out {
    animation: fade_out 0.8s 0.4s forwards;

opacity: 1;
}

@keyframes fade_in {
    0% {
         
            opacity: 0;
        }
       
    
        100% {
        
            opacity: 1;
       
        }


}

.fade_in {
    animation: fade_in 1s 0.6s forwards;
opacity: 0;
 z-index: 3;
}
 @media (max-width: 900px) {
    .phone_3d_cntainer{
        width: 100vw;
        height: 20%;
        /* top: 50%; */
        z-index: 10;
        display: flex ;
        align-items: center;
        justify-content: center;
  
    }
          .phone_3d_cntainer_1 {
              width: 100vw;
              height: 50%;
              /* top: 50%; */
              z-index: 10;
              display: flex;
              align-items: center;
              justify-content: center;
            
          }
        .event_section_section_1 {
            width: 100vw;
            height: fit-content;
            justify-content: center;
            align-items: center;
            left: 0;
            margin-top: 10%;
          
        }
.event_section{
    width: 100vw;
    height: 65%;
    justify-content: center;
    align-items: center;
    left: 0;
}
.event_section_1_left{
    margin: 0;
}
.event_section_1_right {
    margin: 0;
    direction: rtl;
    align-items: start;
}
.silde_left {
    animation: slide_up 1.5s 0.4s forwards;
    top: 60%;

}

.slide_right {
    animation: slide_down 1.5s 0.4s forwards;
    top:10%;
left: 0;
}

.screen_slide_right{
    animation: screen_slide_up 1.5s 0.4s forwards;
        top: 35%;
}
.screen_slide_left{
    animation: screen_slide_down 1.5s 0.4s forwards;
        top: 0%;
}
.paypal_div{
    padding-top: 0;
}
 }
