.loading_data_icnon{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 5;
    top: 0;
    backdrop-filter: blur(40px);
        background-color: var(--clear);
        display: flex;
        align-items: center;
        justify-content: center;
}