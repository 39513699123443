
.sectin_2_main_div{
    display: flex;
    align-items: start;
    gap: 4%;
    width:calc(140%);
    margin-top: 5%;
    margin-right: 8%;
}
.sectin_2_main_div:nth-child(2){
/* margin-right: 10%; */
}
.sectin_2_main_div:nth-child(3) {
    /* margin-right: 20%; */
}
.section_2_arrow{
  
    display: flex;
    align-items: center;
    justify-content: center;
        margin-top: 1.8%;
}
.section_2_arrow_icon{
}
.section_2_phone_container{
    width: 70%;
        height: 100vh;
        position: sticky;
        z-index: 1;
        bottom:  0vh;
}
.poster_container{
  
    width: 20vw;
   
    position: absolute;
    right: 53vw;
    bottom:  -20%;
 
    z-index: 0;
    display: flex;

}
.board_container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.board_container img{
    object-fit: contain;
 width: 100%;
 height: auto;

}

.section_2_main_text{
    display: flex;
    flex-direction: column;
    direction: ltr;
    align-items: end;
    justify-content: center;
    width: 100%;
    text-align: end;
}
.no_need_span{
    font-size: var(--small) !important
    ;
text-align: end;
direction: ltr;
width: 120%;
margin-top: 40px;
font-weight: 700;
}
 @media (max-width: 900px) {
  .poster_container{
    right: 5%;
        bottom: calc(-50vh + 75px);
        width: 50%;
        
  }
  .no_need_span{
    position: absolute;
    bottom: -20px;
  }
 }