.gallery_page{
    font-family: 'Montserrat';
    display: flex;
    align-items: center;
    justify-content: center;
 
}
body{
    touch-action: pan-x pan-y;
}
.gallery_page_header{
    display:  flex;
    flex-direction: column;
padding-top: 10px;
gap: 20px;
left: 0px;
height:fit-content;
position: fixed;
width: 100%;
    top: 0;
    backdrop-filter: blur(40px);
    background-color: var(--clear);
    z-index: 2;
    text-align: center;
    padding-top: 20px;
}
.download_share_container{
position: absolute;
top: 30px;
right: 20px;
display: flex;
align-items: center;
gap: 20px;
}
.download_icon_container {
display: flex;
flex-direction: column;
font-family: 'ארימו';
align-items: center;
direction: rtl;


font-size: 30px;

}
.admin_download_icon_container{display: flex;
flex-direction: column;
font-family: 'ארימו';
align-items: center;
direction: rtl;


font-size: 30px;

}
.share_icon_container{
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat';
        align-items: center;
        direction: rtl;
    
      
        font-size: 20px;
}
   @media (max-width:1100px) {
       .download_icon_container {
           display: none;
       }

   }
.download_icon_container span,.admin_download_icon_container span, .share_icon_container span{
    font-size: 10px;
}
.gallery_header_footer{
    width: 100%;
    padding-top: 5px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;
align-items: center;

}
.gallery_header_footer_btn{
    font-family: 'Montserrat';
    background-color: transparent;
    border: none;
color: rgb(129, 129, 129);
font-size: clamp(10px,3vw,20px);

}
.selected_header{
border-bottom: 1px solid rgb(0, 0, 0);
}
.gallery_event_name{
font-size: clamp(20px, 8vw, 40px);
   
  
}
.selected_header span{color: black;}
.gallery_event_date {
font-size: clamp(16px, 6vw, 30px);
}
.gallery_page_main{
    width:100%;
    height: fit-content;


}
.images_slider_page{
    position: fixed;
    width: 100vw;
   height: calc(100% + 40px);
    z-index: 3;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
background-color: white;

}
.swiper_div_img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width:100%;
    height: 90%;
    object-fit: cover;
                
}

.close_icon{
    position: fixed;
    top: 20px;
    left: 20px;
    font-size: 30px;
    z-index: 4;
 font-weight: 0;
}
.swiper_slider{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image_loader{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.image-wrapper{
  position: relative;
 border-radius: 10px;

}
.renderd_image{
object-fit: cover;
border-radius:3px;
    display: flex;
        align-items: center;
        justify-content: center;
            
}
.gallery_image_cont{
    position: relative;
border-radius:3px;
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
padding:7px;
margin:7.5px;
    display: flex;
        align-items: center;
        justify-content: center;
}
.delete_image{
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
  width: 30px;
  height: 30px;
 
    scale: 1.3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.delete_image_icon{

    border-radius: 50%;
  color: red;
    padding: 5px;
        background-color: rgb(255, 255, 255);
}
.delete_image_page{
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: var(--clear);
    backdrop-filter: blur(40px);
    top: 0;
left: 0;
display: flex;
flex-direction: column;

align-items: center;
justify-content: center;
}
.selete_image_section{
    display: flex;
        flex-direction: column;
    
        align-items: center;
            width: 90%;
                max-width: 400px;
                height: 80%;
}
.delete_image_box{
    width: 90%;
    max-width: 400px;
    height:fit-content;

    background-color: white;
border-radius: 10px 10px 0 0;    direction: rtl;
    text-align: center;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
font-family: 'ארימו';
position: relative;
display: flex;
flex-direction: column;
align-items: center;
        padding-bottom: 10px;
gap: 20px;}
.download_image_page {
    position: fixed;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.866);
    backdrop-filter: blur(100px);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}
.download_image_box{
    width: 90%;
    max-width: 400px;
    height: fit-content;

    background-color: white;
    border-radius:10px;
    direction: rtl;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    font-family: 'ארימו';
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 20px;
    gap: 20px;}
        .download_image_box button {
            font-family: 'ארימו';
    
            border: none;
            font-size: 16px;
    
    
    
        }
    

    
        .download_image_box p {
            font-size: 14px;
            padding-left: 20px;
            padding-right: 20px;
    
    
        }
.delete_image_img {
width: 90%;
    max-width: 400px;
    object-fit: cover;
    height: 50%; 
    flex: 1;
border-radius: 0 0 10px 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.trash_icon{
    font-size: 30px;
    color: red;
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        padding: 10px;
        margin-top: -25px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.close_delete_box{
    position: absolute;
    left:2px;
    top: 2px;
    font-size: 24px;
color:rgb(135, 135, 135);;

}
.delete_image_box  button{
    font-family: 'ארימו';

        border: none;
        font-size: 16px;
       


}
.delete_image_box_span{
    font-size: 20px;
    font-weight: 600;
}
.delete_image_box  p{
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;


}
.delete_image_box_footer{
    width:100% 
    ;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-left: 10px;

}
.delete_image_box_footer_delete{
    border-radius:5px;
   padding: 5px;
    background-color: red;
        color: wheat;
        display: flex;
        align-items: center;
}
.delete_image_box_footer_cancle{
color: rgb(135, 135, 135);
    ;            background-color: transparent;
}

.chech_box_container{
    position: absolute;
    top: 5px;
    left: 5px;
    z-index:1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}