
.event_page{
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
top: 0;
position: fixed;
font-family: 'Montserrat';
        /* transform: scaleX(-1); */
    }

.event_page_bg{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    z-index: -1;
}
.event_page_main{
width: 100%;
flex: 1;
position: relative;
margin-bottom: 0px;
height: 100%;
position: relative;


}
.event_name_footer{
height: fit-content;
width: 100%;
display: flex;
align-items: center;
justify-content: space-around;
padding-bottom: 20px;
padding-top: 20px;
align-items: end;

}
.event_circle{
    width: 20vw;
    height:20vw;
    border-radius: 50%;
    display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 55px;
        min-height: 55px;
        position: relative;
}
.event_page_event_name{
    position: absolute;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
padding-top: 5px;
height:fit-content;
padding-bottom: 5px;
}
.circle_1{
   
max-width: 55px;
    max-height: 55px;
    font-size:28px ;
    margin-bottom: 2px;
    scale: 1;
}
.circle_2{
    width: 22vw;
    height: 22vw;
    max-width: 70px;
    max-height: 70px;
    font-size: 40px;
}
.circle_3{
    font-size: 35px;
        max-width: 55px;
            max-height: 55px;
            scale: 1;
}
.left_circle {
    font-size: 10px;
    position: absolute;
    bottom: 2px;
}
.test{
    position: fixed;
    top: 10;
}

.event_menu_contianer{
    z-index: 2;
width:20%;
height: 100px;

position: fixed;
right: 0;
top: 0;  
}
.menu_icon {
    position: absolute;
    top: 20px;
    right: -200px;
    font-size: 50px;

    transform: rotateY(180deg);
}
@keyframes move_right {
    0% {
        right: 20px;
    }

    20% {
        right: 50px;
    }

    100% {
        right: -350px;
    }
}

.move_right {
    animation: move_right 0.6s 0s forwards;
}

@keyframes move_left {
    0% {
        right: -350px;
    }

    60% {
        right: 50px;
    }

    100% {
        right: 20px;
    }
}
.move_left {
    animation: move_left 0.4s 0s forwards;
    animation-delay: 0.4s;
}
.move_left_div:nth-child(1) {
    animation: move_left 0.4s 0s forwards;
    animation-delay: 0.2s;
}

.move_left_div:nth-child(2) {
    animation: move_left 0.4s 0s forwards;
    animation-delay: 0.6s;
}

.move_left_div:nth-child(3) {
    animation: move_left 0.4s 0s forwards;
    animation-delay: 0.4s;
}

.move_left_div:nth-child(4) {
    animation: move_left 0.4s 0s forwards;
    animation-delay: 0.5s;
}

.event_menu_option {
    border-radius: 30px;
    position: absolute;
    right: -200px;
text-align: center;
padding: 2px;
display: flex;
justify-content: end;
align-items: center;
gap: 10px;
padding-right: 10px;
padding-left: 10px;
width: fit-content;
height: 17px;
}
.event_menu_option span{
white-space: nowrap;}

.event_menu_option:nth-child(2) {
    top: 10px;
}

.event_menu_option:nth-child(1) {
    top: 60px;
}

.event_menu_option:nth-child(3) {
    top:40px;
   
}

.event_menu_option:nth-child(4) {
    top: 130px;

}
.uploading_container{
    position: fixed;
    top: 20px;
    left: 20px;
    direction: rtl;
 
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    padding-right: 10px;
        padding-left: 10px;
gap: 15px;
}
.barcode_page_x{
    top: 30px;
    left: 30px;
    color: black;
    position: absolute;
    scale: 4;
}