.nav_bar_container{
    position: fixed;
    width: calc(100% - 60px);
    border-radius: 50px;
    height: fit-content;
    z-index: 10;
    top: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    z-index: 5;
}
.logo_icon_section{
position: relative;
height: 100%;
display: flex
;
align-items: center;
gap: 10px;
}
.logo_icon_section img{
    height: 60%;
    width: auto;
    object-fit: contain;
        background-color: rgb(20, 119, 150);
        border-radius: 8px;
}
.logo_icon_section span{
    font-size: var(--medium);
    font-family: 'Montserrat';
}
.nav_bar_section{
display: flex;
justify-content: end;
    align-items: center;
    width: 50%;
    gap: 20px;
    
}
.nav_bar_section_button{
    background-color: transparent;
    border: none;
    font-family: 'openSans';
    font-weight: 200;
    direction: rtl;
}
.nav_bar_my_events{
border:1px solid rgb(148, 148, 148);
    background-color: transparent;
    padding:3px;
    padding-left: 10px;
    padding-right: 10px;
border-radius: 30px;
    font-family: 'openSans';
        font-weight: 200;
}

.leng_button{
    background-color: transparent;
    border: none;
    font-family: 'Montserrat';
}
.left_bar_container{
position: fixed;
right: 0;
top:calc(45%);

width: 50px;
z-index: 4;
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
}
.left_bar_section_button{
background-color: transparent;
border: none;
display: flex;
align-items: center;
justify-content: center;

}
.left_bar_section_button_cntainer{
    display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
            width: 10px;
            height: 10px;
            border: 1px solid rgb(123, 123, 123);
            padding: 2px;
}
.active_ball {
    width: 100%;
    height: 100%;
background-color: var(--blue);   
 transition: background-color 1s;
 /* transition-delay: 1s; */
    border-radius: 50%;
}
 @media (max-width: 900px) {
     .nav_bar_container {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
     }}